import hero1Img from 'images/hero/1.webp';
import hero2Img from 'images/hero/2.webp';

import service1Img from 'images/services/service-1.webp';
import service2Img from 'images/services/service-2.webp';
import service3Img from 'images/services/service-3.webp';

import client1Img from 'images/clients/client-1.webp';
import client2Img from 'images/clients/client-2.webp';
import client3Img from 'images/clients/client-3.webp';
import client4Img from 'images/clients/client-4.webp';
import client5Img from 'images/clients/client-5.webp';
import client6Img from 'images/clients/client-6.webp';
import client7Img from 'images/clients/client-7.webp';

import portfolio1Img from 'images/portfolio/1.webp';
import portfolio2Img from 'images/portfolio/2.webp';
import portfolio3Img from 'images/portfolio/3.webp';
import portfolio4Img from 'images/portfolio/4.webp';
import portfolio5Img from 'images/portfolio/5.webp';
import portfolio6Img from 'images/portfolio/6.webp';
import portfolio7Img from 'images/portfolio/7.webp';
import portfolio8Img from 'images/portfolio/8.webp';
import portfolio9Img from 'images/portfolio/9.webp';
import portfolio10Img from 'images/portfolio/10.webp';

import project1Img from 'images/projects/1.webp';
import project2Img from 'images/projects/2.webp';
import project3Img from 'images/projects/3.webp';
import project4Img from 'images/projects/4.webp';
import project5Img from 'images/projects/5.webp';
import project6Img from 'images/projects/6.webp';
import project7Img from 'images/projects/7.webp';

export const navigationLinks = [
  {
    text: 'home',
    href: '#',
  },
  {
    text: 'about',
    href: '#section-about',
  },
  {
    text: 'services',
    href: '#section-services',
  },
  {
    text: 'clients',
    href: '#section-clients',
  },
  {
    text: 'portfolio',
    href: '#section-portfolio',
  },
];

export const contact = {
  phone: '8866095611',
  email: 'peapl.baroda@gmail.com',
  location:
    'https://www.google.com/maps/place/Perfect+Engineering+@+Allied+Works+P.+L./@22.2459077,73.182322,17z/data=!3m1!4b1!4m5!3m4!1s0x395fc5944951e45f:0x4fbe741dccd7c00b!8m2!3d22.2459077!4d73.1845107?hl=en-IN&shorturl=1',
  socials: {
    facebook: 'https://www.facebook.com/peapl.gujarat/',
    instagram: 'https://www.instagram.com/gpel13/',
    linkedin: 'https://www.linkedin.com/in/peapl-gujarat-vadodara-bb1a73214/?originalSubdomain=in',
  },
};

export const services = [
  {
    image: service1Img,
    title: 'Storage & Handling Systems',
    description:
      'LPG Storage Systems and Pipelines, Bulk Transportation Bullets for LPG and Ethyne Oxide / Methanol tanks.',
  },
  {
    image: service2Img,
    title: 'Drying & Processing Equipment',
    description:
      'Vacuum Chamber Dryers, Rotary Double Cone Vacuum Dryers, Continuous Rotary Paddle Dryers, Crystallizers and Leaf Filters, Rotary Vacuum Locks, Fluidised Bed Dryers (Batch and Continuous type).',
  },
  {
    image: service3Img,
    title: 'Chemical Processing',
    description:
      'Hard Anodising of Extruded Aluminium in HE 15 / HE 30 grades, Hard Chrome Plating , Silver Plating for Textiles, Electrical Power and Aviation Industries.',
  },
];

export const clients = [
  client1Img,
  client2Img,
  client3Img,
  client4Img,
  client5Img,
  client6Img,
  client7Img,
];

export const heroImages = [hero1Img, hero2Img];

export const portfolio = [
  {
    image: portfolio1Img,
    title: 'activated carbon adsorber',
    description: 'heavy water plant',
  },

  {
    image: portfolio2Img,
    title: 'rotary vaccum dryer',
    description: 'Ranbaxy Laboratory',
  },

  {
    image: portfolio3Img,
    title: '4000 ltr storage with agitator',
    description: 'netzsch/A> / bangladesh',
  },

  {
    image: portfolio4Img,
    title: '150m³ Propellant Storage Tank',
    description: 'LPSC - Mahendragiri',
  },

  {
    image: portfolio5Img,
    title: 'VCD-16',
    description: 'pharmaceutical',
  },

  {
    image: portfolio6Img,
    title: 'recupurator',
    description: 'TATA energy research institute',
  },

  {
    image: portfolio7Img,
    title: 'hydro power generation',
    description: '/xc',
  },

  {
    image: portfolio8Img,
    title: 'heavy water board',
    description: 'double pipe exchanger',
  },

  {
    image: portfolio9Img,
    title: 'high pressure air storage tank 3M³',
    description: 'defence research & development lab - hyderabad',
  },

  {
    image: portfolio10Img,
    title: 'high pressure vessel - cladded',
    description: '(Boiler Quality Plate + SS304) for DM Water Tank for IPRC 100mm.',
  },
];

export const projects = [
  {
    image: project1Img,
    title: 'butter melter',
    description: 'NETZSCH bangladesh',
  },

  {
    image: project2Img,
    title: 'paint / pigment industries',
    description: 'mixer vessels & storage tanks',
  },

  {
    image: project3Img,
    title: 'loca chamber',
    description: 'for ERDA',
  },

  {
    image: project4Img,
    title: 'GSFC caprolactom',
  },

  {
    image: project5Img,
    title: 'mobile propellant storage',
  },

  {
    image: project6Img,
    title: 'reactor vessel limpet coil',
    description: '75000 LTR reactor vessel',
  },

  {
    image: project7Img,
    title: 'pharmaceutical',
    description: 'rotary vacuum dryers',
  },
];
