import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

import { Grid, Icon, Section } from 'ui';
import logoImg from 'images/logo.webp';
import { contact } from 'utils/data';
import Socials from 'components/socials';

const Footer = () => (
  <Section
    component='footer'
    sx={{ backgroundColor: 'neutral.darkgrey', color: 'grey.50', paddingY: 5 }}>
    <Grid columns={4}>
      <Stack alignItems='center' gap={2}>
        <img src={logoImg} alt='LOGO' height={100} />
        <Typography textTransform='capitalize' width='70%' textAlign='center'>
          perfect engineering and allied works PVT LTD
        </Typography>
      </Stack>

      <Stack gap={2}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Icon name='Phone' size={23} />
          <Typography variant='h5'>Phone</Typography>
        </Stack>

        <Typography>Works: 0265-2642066</Typography>
        <Typography>Telefax: 0265-2636412</Typography>
        <Typography>Office: 0265-2631834</Typography>
        <Typography>Telephone: 1195609988</Typography>
      </Stack>

      <Stack gap={2}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Icon name='Mail' size={23} />
          <Typography variant='h5'>Email</Typography>
        </Stack>

        <Typography>Works: peapl.works@gmail.com</Typography>
        <Typography>Office: peapl.baroda@gmail.com</Typography>
      </Stack>

      <Stack gap={2}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Icon name='MapPin' size={23} />
          <Typography variant='h5'>Location</Typography>
        </Stack>

        <Typography>
          <a href={contact.location} target='_blank' rel='noreferrer'>
            Registered Office / Works: 846/847 GIDC Makarpura Vadodara 390010
          </a>
        </Typography>
      </Stack>
    </Grid>

    <Divider sx={{ backgroundColor: 'neutral.lightgrey', my: 4 }} />

    <Stack
      direction={{ xs: 'column', md: 'row' }}
      gap={3}
      justifyContent='space-between'
      alignItems='center'>
      <Socials />
      <Typography>
        &copy; Copyright {new Date().getFullYear()} &mdash; Developed by FIBO 1618.
      </Typography>
    </Stack>
  </Section>
);

export default Footer;
