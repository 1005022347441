import React from 'react';
import { Stack, styled } from '@mui/material';

import { contact } from 'utils/data';
import { Icon } from 'ui';

const Socials = () => (
  <Stack direction='row' gap={2}>
    <SocalIcon target='_blank' href={`mailto:${contact.email}`}>
      <Icon color='grey.800' name='Mail' />
    </SocalIcon>
    <SocalIcon target='_blank' href={contact.socials.instagram}>
      <Icon color='grey.800' name='Instagram' />
    </SocalIcon>
    <SocalIcon target='_blank' href={contact.socials.facebook}>
      <Icon color='grey.800' name='Facebook' />
    </SocalIcon>
    <SocalIcon target='_blank' href={contact.socials.linkedin}>
      <Icon color='grey.800' name='Linkedin' />
    </SocalIcon>
  </Stack>
);

export default Socials;

const SocalIcon = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.neutral.lightgrey,
  padding: '.5rem',
  borderRadius: '100%',
  transition: 'transform .3s',
  willChange: 'transform',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));
